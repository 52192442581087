body {
  font-family: "Poppins", sans-serif !important;
}
#root,
.main-wrapper {
  height: 100%;
}
/* ============ Hone page css Start ============ */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper {
  background-color: hsl(228deg 43% 21%);
  overflow-x: hidden;
  border: 0;
  left: -250px;
  transition: all 0.3s ease;
  z-index: 900 !important;
  justify-content: space-between;
  align-items: center;
}
.version_code{
  color: #fff;
  margin-bottom: 10px;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular h3 {
  color: #1e274c !important;
  font-weight: 600;
  text-align: center;
  margin-top: 7px;
}
.ul-main-block {
  padding: 10px 20px !important;
}
.ul-main-block .list-item-block {
  background-color: unset;
  padding: 0;
  width: 176px;
  height: 46px;
  border-radius: 10px;
  padding: 0 0 0 20px;
  transition: all 0.4s ease;
}
.ul-main-block .list-item-block + .list-item-block {
  margin: 7px 0 0 0;
}
.ul-main-block .active.list-item-block {
  background-color: #fff;
}
.ul-main-block .active.list-item-block .list-span span {
  color: #ee7c45;
  font-weight: 700;
}
.ul-main-block .active.list-item-block svg path {
  fill: #ee7c45;
}
.ul-main-block .list-item-block:hover {
  background-color: #fff;
}
.ul-main-block .list-item-block:hover svg path {
  fill: #ee7c45;
}
.ul-main-block .list-item-block:hover .list-span span {
  color: #ee7c45;
  font-weight: 700;
}
.ul-main-block .list-item-block .MuiListItemIcon-root {
  min-width: unset;
}
.ul-main-block .list-item-block svg {
  width: 24px;
  height: 24px;
  transform: scale(1.2);
  margin-right: 18px;
}
.ul-main-block .list-item-block svg path {
  fill: #fff;
  transition: all 0.4s ease;
}
.ul-main-block .list-item-block .list-span {
  margin: 0;
}
.ul-main-block .list-item-block .list-span span {
  font-weight: 400;
  font-size: 16px;
  line-height: 0.7;
  text-transform: capitalize;
  color: #ffffff;
  transition: all 0.4s ease;
  font-family: Poppins, sans-serif !important;
}
.list_a_block {
  display: flex;
  align-items: center;
}
.app-bar-block {
  margin-top: 0 !important;
  background-color: #1e274c !important;
}
.app-bar-block .MuiToolbar-root.MuiToolbar-gutters {
  padding-left: 17px !important;
}
.font-block-appbar {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 1;
  color: #fff;
}
.logo-block img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 0 5px 0 !important;
}
.toolbar-block-css {
  padding: 16px 8px !important;
}
.admin-block-css {
  position: relative;
  padding-left: 11px;
  margin-top: 35px;
}
.admin-block-css::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
  background-color: #ee7c45;
}
.admin-block-css h6 {
  cursor: pointer !important;
  transition: all 0.4s ease;
  word-wrap: break-word;
}
.admin-block-css h6:hover {
  color: #ee7c45 !important;
}
.column-block-css
  .ui.stackable
  .column.css-hover-block
  .MuiPaper-root.MuiPaper-elevation {
  box-shadow: unset !important;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  text-align: center;
  padding: 0px 10px 25px 10px;
  min-height: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.column-block-css .ui.stackable .column.css-hover-block > div:last-child {
  height: unset !important;
}
.column-block-css
  .ui.stackable
  .column.css-hover-block
  .MuiPaper-root
  div:last-child
  button {
  z-index: 111;
  position: relative;
}
.column-block-css
  .ui.stackable
  .column.css-hover-block.disabled_card
  .MuiPaper-root
  div:last-child
  button {
  background-color: gray !important;
  border-color: gray !important;
}
.column-block-css
  .ui.stackable
  .column.css-hover-block
  .MuiPaper-root
  div:last-child
  p {
  margin: 9px 0;
  line-height: 1 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.column-block-css
  .ui.stackable
  .column.red_box
  .MuiPaper-root
  div:last-child
  p {
  color: #ff7678;
}
.column-block-css
  .ui.stackable
  .column.green_box
  .MuiPaper-root
  div:last-child
  p {
  color: #4fddb0;
}
.column-block-css
  .ui.stackable
  .column.green_box.disabled_card
  .MuiPaper-root
  div:last-child
  p {
  color: gray;
}
.column-block-css
  .ui.stackable
  .column.yellow_box
  .MuiPaper-root
  div:last-child
  p {
  color: #ffc061;
}
.column-block-css
  .ui.stackable
  .column.white_box
  .MuiPaper-root
  div:last-child
  p {
  color: #77a6ff;
}
.green_box .event_tota_title {
  color: #4fddb0;
  background: rgba(79, 221, 176, 0.1);
}
.column-block-css .column .MuiPaper-root {
  position: relative;
}
.column-block-css .column .MuiPaper-root::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.column-block-css .table-main-block .column .MuiPaper-root::after {
  display: none;
}
.column-block-css
  .ui
  .column.css-hover-block.azure_box
  .MuiPaper-root
  div:last-child
  p {
  color: #77a6ff;
}
.column-block-css
  .ui
  .column.css-hover-block.pink_box
  .MuiPaper-root
  div:last-child
  p {
  color: #dc94ff;
}
.column-block-css .column.css-before-block .MuiPaper-root::after {
  display: none;
}
.column-block-css .column .MuiPaper-root:hover::after {
  height: 100%;
}
.column-block-css .column .MuiPaper-root:hover .card-icons-block img {
  filter: brightness(0) invert(1);
}
.column-block-css .column.css-hover-block .MuiPaper-root:hover p {
  color: #fff !important;
  z-index: 11;
  position: relative;
}
.column-block-css .column .MuiPaper-root.MuiPaper-elevation > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 10px 40px;
  border-radius: 5px;
  display: inline-block;
}
.green_box.column .MuiPaper-root.MuiPaper-elevation > p {
  color: #4fddb0;
  background: rgba(79, 221, 176, 0.1);
}
.green_box.column.disabled_card .MuiPaper-root.MuiPaper-elevation > p {
  color: gray;
  background: rgba(128, 128, 128, 0.1);
}
.yellow_box.column .MuiPaper-root.MuiPaper-elevation > p {
  color: #ffc061;
  background: rgba(255, 192, 97, 0.1);
}
.red_box.column .MuiPaper-root.MuiPaper-elevation > p {
  color: #ff7678;
  background: rgb(255 117 120 / 10%);
}
.azure_box.column .MuiPaper-root.MuiPaper-elevation > p {
  color: #77a6ff;
  background: rgba(119, 166, 255, 0.1);
}
.azure_box.column .MuiPaper-root .card-icons-block img {
  filter: invert(93%) sepia(70%) saturate(4494%) hue-rotate(196deg)
    brightness(130%) contrast(108%);
}
.column .MuiPaper-root.MuiPaper-elevation:hover .span-description-css {
  color: #fff;
}
.column .MuiPaper-root.MuiPaper-elevation .span-description-css {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: #6c6c6c;
  margin-top: 10px;
  z-index: 11;
}
.pink_box.column .MuiPaper-root.MuiPaper-elevation > p {
  color: #dc94ff;
  background: rgba(220, 148, 255, 0.1);
}
.blue_box.column .MuiPaper-root.MuiPaper-elevation > p {
  color: #51b0f6;
  background: rgba(81, 176, 246, 0.1);
}
.green_box .MuiPaper-root::after {
  background: #4fddb0;
}
.green_box.disabled_card .MuiPaper-root::after {
  background: rgba(128, 128, 128, 0.4);
}
.yellow_box .MuiPaper-root::after {
  background: #ffc061;
}
.red_box .MuiPaper-root::after {
  background: #ff7678;
}
.azure_box .MuiPaper-root::after {
  background: #77a6ff;
}
.pink_box .MuiPaper-root::after {
  background: #dc94ff;
}
.blue_box .MuiPaper-root::after {
  background: #51b0f6;
}
.table-main-block .column .MuiPaper-root .MuiPaper-elevation:hover {
  background-color: transparent;
}
.css-hover-block {
  position: relative;
}
.card-icons-block {
  margin-bottom: 10px;
  z-index: 111;
  position: relative;
}
.card-icons-block img {
  width: 80px;
}
.disabled_card .card-icons-block img {
  filter: invert(52%) sepia(0%) saturate(0%) hue-rotate(25deg) brightness(98%)
    contrast(85%);
}
.table {
  border-radius: 15px !important;
  overflow: hidden;
}
.table-main-block .column .MuiPaper-root.MuiPaper-elevation {
  background-color: #f4f4f6;
}
.table-main-block .column .css-p-table > p {
  font-size: 22px !important;
}
.cm_table_responsive .th_item_wrap {
  padding: 15px;
}
.view-hover-set {
  color: #1e274c !important;
  transition: all 0.4s ease;
}
.view-hover-set:hover {
  color: #ee7c45 !important;
}
.logout-css-modal-block {
  padding: 20px;
}
.logout-css-modal-block > p {
  padding: 0;
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 5px;
}
.logout-css-modal-block > h2 {
  padding: 0;
}
.logout-button-block {
  display: flex;
  align-items: center;
}
.logout-button-block > div {
  opacity: 0 !important;
  margin: 0 7.5px;
}
.logout-button-block > p {
  background-color: #1e274c;
  border: 1px solid #1e274c;
  color: #fff;
  padding: 6px 16px;
  font-size: 16px;
  border-radius: 4px;
  transition: all 0.4s ease;
  cursor: pointer;
}
.cancel_outline > p {
  background-color: transparent;
  color: #1e274c;
}
.logout-button-block > p:hover {
  background-color: transparent;
  color: #1e274c;
}
.app_bar_svg_block > svg {
  width: 26px !important;
  height: 26px !important;
  margin-right: 9px;
}
.new_cycle_page_css {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.disabled_card {
}
/* ============ Hone page css End ============ */

/* ============ login page css start ============ */
.main-wrapper {
  position: relative;
}
.main-wrapper .main-wrapper-bg-img {
  position: fixed;
  bottom: 10px;
  right: 10px;
  opacity: 0.2;
  z-index: -11;
}
.main-logo-block-css img {
  max-width: 250px;
  width: 100%;
  cursor: pointer;
}
.main-form-block .ui.segment {
  width: 100%;
  max-width: 40%;
  margin: 0 auto;
}
.main-form-block .ui.segment {
  border: 2px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
}
.main-form-block .ui.segment .main-logo-block-css h2 {
  font-size: 35px;
}
.main-form-block .ui.segment .field label,
.main-form-block .ui.segment .move_register_container .move_register,
.main-form-block .ui.primary.button,
.input-css-block input + i.icon,
.pons-button-css-block button,
.report-button-css-block > button {
  transition: all 0.4s ease !important;
}
.main-form-block .ui.segment .field label:hover,
.main-form-block .ui.segment .move_register_container .move_register:hover {
  color: #ee7c45;
}
.main-form-block .ui.segment .field input:focus {
  border: 1px solid #ee7c45 !important;
}
.main-form-block .ui.segment .move_register_container .move_register {
  cursor: pointer;
}
.main-form-block .ui.primary.button {
  background-color: #1e274c;
  font-size: 16px;
}
.main-form-block .ui.primary.button:hover {
  background-color: #ee7c45;
}
.alarms-css-drop
  tbody
  tr
  td
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  font-weight: 400;
  font-size: 18px;
  line-height: 2;
  font-size: 15px;
  text-transform: capitalize;
  color: #101010;
  font-family: "Poppins", sans-serif;
}
.alarms-css-drop
  tbody
  tr
  td
  .MuiInputBase-root.MuiInputBase-colorPrimary
  svg
  path {
  fill: #101010;
}
td {
  border-style: unset;
  border-color: unset;
}
/* ============ login page css End ============ */

/* ============ ponds page css Start ============ */
.input-css-block input:focus {
  border: 1px solid #ee7c45 !important;
}
.input-css-block input:focus + i.icon:before {
  color: #ee7c45;
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  background-color: #ee7c45 !important;
  color: #fff !important;
  opacity: 1 !important;
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters:hover {
  background-color: rgb(238 124 69 / 52%);
}
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input:focus {
  box-shadow: 0 0 0 0.2rem rgb(238 124 69 / 25%);
  border-color: #ee7c45;
}
.menuContainer-css-block p:hover {
  background-color: #ee7c45;
  color: #fff;
}
.menuContainer-css-block {
  min-width: 100px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 15%) !important;
}
.edit-css-box {
  padding: 15px;
  background-color: #394060;
  color: #fff;
}
.MuiButtonBase-root.MuiButton-root.MuiLoadingButton-root {
  background-color: #1e274c;
}
.MuiButtonBase-root.MuiButton-root.MuiLoadingButton-root:hover {
  background-color: #ee7c45;
}
.block-cards-css-ponds
  .column
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
  background-color: #f2a27e;
  border-radius: 8px;
}
.block-cards-css-ponds
  .column
  .MuiTypography-root.MuiTypography-body1.hedging-css-start {
  padding: 11px 0px;
  display: block;
  font-size: 16px;
  line-height: 1.5;
}
.edit-css-box > div + div {
  margin-top: 7px;
}
.select-main-block > .MuiBox-root > p {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 10px 12px;
  white-space: nowrap;
}
.select-main-block
  > div
  .MuiInputBase-root.MuiInputBase-colorPrimary
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  width: 100%;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
  margin: 0;
  min-width: 220px;
  padding: 0 14px 0 14px;
  height: 41px;
  display: flex;
  align-items: center;
}
.select-main-block > div .MuiInputBase-root.MuiInputBase-colorPrimary svg {
  right: 11px;
}
.input-css-block input {
  border-radius: 35px !important;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%) !important;
}
.input-css-block .search.icon {
  right: 6px !important;
}
.add-Pond-location .hiding-pond-css {
  background-color: #1e274c !important;
}
.add-Pond-location .hiding-pond-css i {
  cursor: pointer;
  transition: all 0.4s ease;
}
.add-Pond-location .hiding-pond-css i:hover {
  color: #ee7c45 !important;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  color: #ee7c45 !important;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline:after {
  border-bottom: 2px solid #ee7c45 !important;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline:hover:not(
    .Mui-disabled
  ):before {
  border-bottom: 2px solid #1e274c !important;
}
.MuiSwitch-thumb {
  color: gray !important;
}
.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked
  .MuiSwitch-thumb {
  color: #ee7c45 !important;
}

.super_admin_table .cm_td:last-child {
  justify-content: center;
}
.super_admin_table .cm_td.user_cm:last-child {
  justify-content: end;
}
.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked
  + .MuiSwitch-track {
  background-color: #1e274c;
}
.MuiButtonBase-root.MuiSwitch-switchBase + .MuiSwitch-track {
  background-color: rgba(128, 128, 128, 0.29);
  opacity: 1 !important;
}
.head_container.hedera_ui_css_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head_container.hedera_ui_css_block .hedera_flex_block_row {
  display: flex;
  align-items: flex-end;
}
.head_container.hedera_ui_css_block .hedera_flex_block_row > div:last-child {
  margin: 0 0 1px 15px;
}
.head_container.hedera_ui_css_block
  .hedera_flex_block_row
  .head_one
  > .ui.grid {
  margin: 0 !important;
}
.hedera_ui_css_block .pons-button-css-block {
  margin: 0;
}
.hedera_ui_css_block .pons-button-css-block input {
  display: none;
}
.head_container.hedera_ui_css_block.alarm_margin {
  margin: 0 !important;
}
/* ============ ponds page css End ============ */

/* ============ report page css Start ============ */
.from-input-data-css input:focus {
  border-color: #ee7c45 !important;
  outline: none !important;
}
.Select-bg-color > div {
  background-color: #fff;
}
.repot-main-block-css {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-top: -20px;
}
.repot-main-block-css .repot-input-box {
  border: 2px solid #eee;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%) !important;
  padding: 20px;
  min-width: 500px;
}
.report-button-css-block {
  display: flex;
  justify-content: space-around;
}
.repot-main-block-css .repot-input-box .input-block {
  display: flex;
  flex-direction: column;
}
.repot-main-block-css .repot-input-box .input-block div + div {
  margin-top: 18px;
}
.repot-main-block-css .repot-input-box .input-block > div > p {
  font-size: 15px;
}
.repot-main-block-css .repot-input-box .input-block > div > .MuiBox-root {
  margin: 0;
}
.repot-main-block-css
  .repot-input-box
  .input-block
  > div
  > .MuiBox-root
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  width: 100%;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
  margin: 0;
  min-width: 220px;
  padding: 13px;
}
.repot-main-block-css .repot-input-box .input-block .from-input-data-css input {
  width: 100%;
  margin: 0 !important;
  border-radius: 35px !important;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
  padding: 24.5px 0;
}
/* ============ report page css End ============ */

/* ============ Settings page css Start ============ */
.setting_padding_css {
  justify-content: flex-end;
}
.setting_padding_css.head_container {
  margin: 0 0 15px 0 !important;
}
.setting_padding_css .head_one .ui.grid {
  margin: 0 !important;
}
.setting_padding_css .head_one .ui .base_container {
  width: 100% !important;
  margin: 0;
  padding: 0;
}
.delete_bg_color {
  background-color: #ee3535 !important;
  border: 1px solid #ee3535 !important;
}
.delete_bg_color:hover {
  color: #ee3535 !important;
  background-color: transparent !important;
}
.setting_max_block {
  max-width: 86%;
  margin-left: auto;
  margin-top: -75px;
}
.setting_table_bg_block {
  background-color: transparent !important;
  box-shadow: unset !important;
  border: 1px solid transparent !important;
  border-radius: 0px !important;
  padding: 10px 20px !important;
}
.Settings-roow-color {
  margin-top: -28px !important;
}
.inner_dropdown_block .Settings-roow-color {
  flex-direction: column !important;
  display: inline-block !important;
  margin: 0 !important;
}
.inner_dropdown_block .Settings-roow-color .ui.segment {
  border-bottom: 0 !important;
  border-left: 3px solid transparent !important;
  font-size: 15px;
  padding: 8px 15px;
}
.inner_dropdown_block .Settings-roow-color .ui.inverted.blue.segment {
  border-bottom: 0 !important;
  border-left: 3px solid #1e274c !important;
}
.setting_main_box {
  border: 0px;
  background-color: #fff !important;
  box-shadow: unset !important;
}
.Settings-roow-color .ui.inverted.blue.segment {
  background-color: transparent !important;
  color: #1e274c !important;
  border-bottom: 2px solid #1e274c !important;
}
.Settings-roow-color .ui.segment {
  font-size: 16px;
  flex: none !important;
  padding: 10px 25px;
  color: #919faf;
  border-left: 0 !important;
  border-bottom: 2px solid #e4e8ef !important;
  cursor: pointer;
}
.Settings-roow-color {
  border: 0px !important;
  margin-bottom: 18px;
  background-color: transparent !important;
  box-shadow: unset !important;
}
.field > input:focus {
  border-color: #ee7c45 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  appearance: textfield;
}
button {
  background-color: #1e274c !important;
  transition: all 0.4s ease !important;
  border: 1px solid #1e274c !important;
  color: #fff !important;
}
button:hover {
  background-color: transparent !important;
  color: #1e274c !important;
}
.farm-svg-css > div {
  margin: 0 4px;
}
.user-manage-tab tbody tr td .farm-svg-css svg {
  cursor: pointer;
  z-index: 11;
  position: relative;
  margin: 0;
}
.user-manage-tab tbody tr td .delete_icon_td > div svg path {
  fill: rgba(239, 83, 80) !important;
}
.user-manage-tab tbody tr td .farm-svg-css > div {
  width: 36px;
  height: 36px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-manage-tab tbody tr td .farm-svg-css svg path:not(:nth-child(2)) {
  transition: all 0.4s ease !important;
}
.user-manage-tab tbody tr td .farm-svg-css > div:first-child svg path {
  transition: all 0.4s ease !important;
}
thead tr th {
  background-color: #1e274c !important;
  color: #fff !important;
  position: relative;
  font-size: 15px;
}
table tbody tr td {
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}
table tbody tr td {
  color: #000 !important;
}
.home_table tbody tr td {
  vertical-align: middle;
  color: #fff !important;
}
table tbody tr td > p {
  color: #000 !important;
}
thead tr th {
  border: 1px solid #999b9f !important;
}
.head_container.head-css-block {
  margin: 37px 0 30px 0;
}
.form-general-css-block form .two.fields {
  margin-bottom: 18px;
}
.form-general-css-block form .two.fields .field input {
  border-radius: 35px !important;
  box-shadow: 0 1px 5px #c1becd !important;
  padding: 14px;
  opacity: 1;
}
.form-general-css-block form .two.fields .field label {
  font-size: 15px;
}
.form-general-css-block form .two.fields .field select {
  border-radius: 35px !important;
  box-shadow: 0 1px 5px #dad1ff !important;
  padding: 14px;
  height: unset;
  appearance: none;
}
.form-general-css-block form .two.fields .field .before-select-css {
  position: relative;
}
.form-general-css-block form .two.fields .field .before-select-css::before {
  content: "";
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 13px;
  height: 13px;
  background-image: url(./images/arrow_icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 111;
}
.form-general-css-block form .two.fields:last-child {
  justify-content: center;
  display: flex;
  /* margin-top: 15px; */
}
.no-alarms-found-css {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-alarms-found-css > img {
  max-width: 400px;
  width: 100%;
}
.no-alarms-found-css > p {
  font-size: 20px;
  color: #1e274c;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-top: 12px;
}
input[type="radio"]:checked {
  background-color: #93e026 !important;
}
.card-css-block {
  padding: 18px !important;
}
.card-css-block p:last-child {
  font-size: 17px;
}
.card-css-block p:first-child {
  color: #ee7c45;
}
.manage-cycle-css .column .MuiCardContent-root {
  padding: 16px !important;
}
.manage-cycle-css .column .MuiCardContent-root > div p {
  font-family: "Poppins", sans-serif !important;
}
.manage-cycle-css .column .MuiCardContent-root > div p + p {
  margin-top: 6px;
}
.cursor_pointer {
  cursor: pointer;
}
/* ============ Settings page css End ============ */

/* ============ CARDS TABLE css Start ============ */
/*========================== Inventory Page Css Start ==========================*/
.cards_main_block {
  margin-top: 30px;
  width: 100%;
  height: 70vh;
}

.header_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .table-responsive {
  height: 100%;
  overflow-x: unset;
} */
.cm_table_responsive.table-responsive {
  height: 100%;
  overflow-x: unset;
}
.table-responsive.cm_table_responsive::-webkit-scrollbar-track,
.table-responsive.cm_table_responsive::-webkit-scrollbar-thumb {
  background: transparent;
}
.inventory_table_wrap {
  border-radius: 12px;
  padding: 20px;
  height: 100%;
}
.th_list.cm_name {
  padding: 0 !important;
}
.sorting_heading{
  cursor: pointer;
}
.sorting_heading h6{
  margin-bottom: 0;
  user-select: none;
}
.sort_icon{
  color:black;
  font-size:15px;
}
.cm_table {
  width: 100%;
  border-collapse: unset;
  border-spacing: 0;
  background-color: transparent !important;
  border: 0 !important;
  caption-side: unset;
}
.th_item_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.th_item_wrap h6 {
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  /* text-transform: capitalize; */
  color: #6c6c6c;
  margin-right: 5px;
  text-align: center;
}

.google-map-css button {
  background-color: #fff !important;
  color: #1e274c !important;
}
.cm_table_responsive .cm_tbody {
  /* padding-bottom: 30px; */
  max-height: 570px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 14px;
  display: block;
}
.inventory_table_wrap thead{
  padding-right: 24px;
}
.inventory_table_wrap thead,
.inventory_table_wrap tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
}
.inventory_table_wrap tbody tr.cm_tr {
  border-radius: 9px;
  border: 1px solid rgba(108, 108, 108, 0.2) !important;
  height: fit-content;
}
.cm_tr.view.unpaid-line{
  border-color: red !important;
}
.cm_tr.unpaid-line.cm_tr:hover {
  border-color: red !important;
  box-shadow: 0px 10px 20px rgb(255 0 0 / 12%);
}
.cm_tr.cm_tr:hover {
  border-color: #7f53f3 !important;
  box-shadow: 0px 18px 50px rgb(127 83 243 / 12%);
}
.tbl-content table {
  padding-right: 14px;
}
.cm_tr {
  background: #ffffff;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
.cm_td {
  border-left: 0 !important;
  border-right: 0 !important;
  transition: all 0.3s ease-in-out 0s !important;
  -webkit-transition: all 0.3s ease-in-out 0s !important;
  padding: 9px 15px !important;
  text-align: center !important;
  line-height: 2.2;
}
.cm_td > h5 {
  justify-content: center;
}
.cm_td:last-child {
  border-right: 1px solid rgba(108, 108, 108, 0.2) !important;
  border-radius: 0 9px 9px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.user-manage-tab .cm_tbody .cm_td h4 {
  line-height: 2.2;
}
.cm_td h4 {
  font-weight: 400;
  font-size: 17px;
  line-height: 1.4;
  color: #101010;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 70px;
  word-wrap: break-word;
}
.cm_td h4 > .MuiFormControl-root.MuiFormControl-fullWidth {
  height: 100%;
  margin: 0;
}
.cm_td p {
  min-width: 36px;
  min-height: 36px;
  border: 0;
  background: rgba(239, 83, 80, 0.1);
  border-radius: 7px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  order: 3;
  margin: 0 4px;
}
.cm_td p:first-child {
  background: rgb(76 176 81 / 10%);
  order: 2;
}
.cm_td .green_icon_edit {
  background: rgb(76 176 81 / 10%);
}
.cm_td .green_icon_edit svg path {
  fill: #4cb051;
}
.cm_td p:first-child svg path {
  fill: #4cb051;
}
.look_icon_css.red_icon_eye {
  background-color: rgba(239, 83, 80, 0.1);
}
.look_icon_css.red_icon_eye svg path:not(:nth-child(2)) {
  fill: rgba(239, 83, 80);
}
.look_icon_css.green_icon_eye {
  background-color: rgb(76 176 81 / 10%);
}
.look_icon_css.green_icon_eye svg path {
  fill: #4cb051;
}
.look_icon_css.blue_icon_eye {
  background-color: #51b0f61a !important;
}
.look_icon_css.blue_icon_eye svg path {
  fill: #51b0f6 !important;
}
.look_icon_css.orange_icon_eye,
.pond_record_icon_set .look_icon_css.orange_icon_eye {
  background-color: rgb(255 165 0 / 10%) !important;
}
.cm_td .look_icon_css.orange_icon_eye svg path,
.look_icon_css.orange_icon_eye svg path {
  fill: orange !important;
}
.active_icon {
  color: #fff;
}
.add-Pond-location_popup {
  max-width: 800px;
  min-width: 670px;
  width: auto !important;
}
.cm_thead_list th {
  background-color: transparent !important;
  border: 0 !important;
}
.th_item_wrap {
  padding: 20px 20px;
}
.green_text,
.cm_colors_green td[data-label="Type"] h4,
td[data-label="Type"].cm_colors_green h4 {
  color: #4cb051;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green_text span,
.cm_colors_green td[data-label="Type"] span,
td[data-label="Type"].cm_colors_green span {
  width: 8px;
  height: 8px;
  background: #4cb051;
  border-radius: 50px;
  margin-right: 5px;
}
.cm_tr_open_block .cm_td {
  border-color: #7f53f3 !important;
  box-shadow: 0px 18px 50px rgb(127 83 243 / 12%);
}
.open_tr_block {
  background-color: rgb(255 255 255 / 0.6);
}
.open_tr_block > td {
  border: 0 !important;
}
.red_text,
.cm_colors_lightRed td[data-label="Type"] h4,
td[data-label="Type"].cm_colors_lightRed h4 {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.red_text span,
.cm_colors_lightRed td[data-label="Type"] span,
td[data-label="Type"].cm_colors_lightRed span {
  background: red;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  margin-right: 5px;
}
.yellow_text,
.cm_colors_yellow td[data-label="Type"] h4 {
  color: rgb(255 205 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.yellow_text span,
.cm_colors_yellow td[data-label="Type"] span {
  background: rgb(255 205 0);
  width: 8px;
  height: 8px;
  border-radius: 50px;
  margin-right: 5px;
}
.yellow_text,
.cm_colors_orange td[data-label="Type"] h4 {
  color: #f57040;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yellow_text span,
.cm_colors_orange td[data-label="Type"] span {
  background: #f57040;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  margin-right: 5px;
}
.white_text,
.cm_colors_blue td[data-label="Type"] h4 {
  color: rgb(94, 180, 248);
  display: flex;
  align-items: center;
  justify-content: center;
}
.white_text span,
.cm_colors_blue td[data-label="Type"] span {
  background: rgb(94, 180, 248);
  width: 8px;
  height: 8px;
  border-radius: 50px;
  margin-right: 5px;
}
.cm_hi {
  height: 13px;
}
.edit_user_popup_css span {
  color: #8e8e8e;
}
.edit_user_popup_css + .edit_user_popup_css {
  margin-top: 5px;
}
.setting_invoice_table_block .open_toggle_svg_css {
  line-height: 2.5;
}
.setting_invoice_table_block .open_toggle_svg_css svg {
  width: 27px;
  height: 27px;
}
.setting_invoice_table_block .cm_td p:first-child {
  background: unset;
  margin: unset;
  order: unset;
  width: unset;
}
.setting_invoice_table_block .cm_table_responsive {
  min-height: 300px;
  height: unset;
}
.logout-css-modal-block > hr {
  display: none !important;
}
.submit-logout-button-block > * {
  width: 50% !important;
}
.cards_main_block.cards_main_block_home {
  margin-top: 15px;
}
.submit-logout-button-block > button {
  background-color: #1e274c;
  border: 1px solid #1e274c;
  color: #fff;
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  transition: all 0.4s ease;
  cursor: pointer;
  outline: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.alarms-css-drop .th_list.cm_name {
  text-align: center !important;
  pointer-events: none;
}
.alarms-css-drop .th_item_wrap {
  margin: 15px auto;
  padding: 0;
  width: auto;
  display: inline-block;
  pointer-events: auto;
}
/*========================== Inventory Page Css End ==========================*/

/*========================== Sidebar Css Start ==========================*/
.open_icon_block {
  position: fixed;
  top: 70px;
  left: 0px;
  background-color: #1e274c;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.6s ease;
  z-index: 999;
}
.toggled_icon .open_icon_block {
  left: -40px;
  transition: all 0.3s ease;
}
.toggled_icon .close_icon_block {
  display: block !important;
}
.close_icon_block {
  position: absolute;
  top: 93px;
  left: 198px;
  z-index: 999;
  display: none !important;
}
.close_icon_block > svg {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.close_icon_block > svg path,
.open_icon_block > svg path {
  fill: #fff;
  transition: all 0.4s ease-in-out;
}
.close_icon_block > svg:hover path {
  fill: #ee7c45;
}
.toggled .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper {
  left: 0px !important;
  transition: all 0.3s ease;
}
.app-bar-block {
  width: 100% !important;
  margin-left: 0 !important;
  transition: all 0.3s ease;
}
.toggled .app-bar-block {
  transition: width 0.6s;
  width: calc(100% - 240px) !important;
  margin-left: 240px !important;
  transition: all 0.3s ease;
}
.toggled .nav_box_block {
  width: 240px !important;
  transition: all 0.3s ease;
}
.nav_box_block {
  width: 0 !important;
  transition: all 0.3s ease;
}
.image_block_css {
  display: flex;
  justify-content: center;
  width: 100%;
}
.gallery_block #ReactGridGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.gallery_block #ReactGridGallery .ReactGridGallery_tile {
  background: unset !important;
  margin: 0 !important;
}
.main_image_box {
  max-width: 50%;
}
.line_chart_main_block {
  max-width: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.ui.stackable.two.column.grid.pond-details-row > div {
  width: 33.33%;
}
.Water_parameters_box .card-icons-block img {
  filter: invert(52%) sepia(43%) saturate(828%) hue-rotate(309deg)
    brightness(110%) contrast(118%);
  width: 75px;
  height: 75px;
}
.parameters_form {
  margin: 20px 0;
}
.add_parameters_input label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 1;
}
.add_parameters_input {
  margin-bottom: 15px;
}
.add_parameters_input input {
  width: 100%;
  padding: 7px 10px;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 13px;
  color: #000;
}
.view_more_box {
  position: relative;
}
.line_chart_main {
  background: #fff;
  border-radius: 10px;
  padding: 0 10px 25px;
  width: 100%;
}
.collaps_gallary {
  overflow: hidden;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin: 0 -5px;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 0 0.75rem 0.25rem rgb(0 0 0 / 10%);
  height: 410px;
}
.collaps_gallary span {
  width: 50%;
  padding: 0 5px;
  cursor: pointer;
  height: 100%;
}
.collaps_gallary_100 span {
  width: 100%;
  padding: 0 5px;
  cursor: pointer;
  height: 100%;
}
.collaps_gallary span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery_block_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -15px;
}
.gallery_block_row > .collaps_gallary_main {
  width: 33.33%;
  padding: 15px;
}
.gallery_block_row.viewlink_records_block {
  z-index: 9999;
  position: relative;
  height: 99vh;
  justify-content: center;
}
.gallery_block_row.viewlink_records_block .collaps_gallary {
  height: 90vh;
}
.gallery_block_row.viewlink_records_block .collaps_gallary span img {
  object-fit: unset;
}
.gallery_block_row.viewlink_records_block .collaps_gallary_main {
  width: auto;
}
.lb-container {
  z-index: 999 !important;
}
.view_more_btn button {
  line-height: 1.5;
  letter-spacing: 0.00938em;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 11px 21px;
  border-radius: 4px;
}
.view_more_row {
  display: flex;
  margin: 0 -20px;
}
.view_more_row > div {
  width: 50%;
  padding: 0 20px;
}
.view_more_box .line_chart_main {
  text-align: center;
  min-height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e3;
}
.view_more_box .line_chart_main span img {
  width: 80px;
}
.view_more_box .line_chart_main h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 10px 40px;
  border-radius: 5px;
  display: inline-block;
  color: #ee7c45;
  background: rgb(238 124 69 / 20%);
  margin: 18px 0 18px 0;
}
.modal_image_row_cm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.modal_image_row_cm > div {
  width: 50%;
  text-align: center;
  padding: 0 10px;
  position: relative;
  margin: 0 auto;
}
.modal_image_row_cm > div h4 {
  font-family: "Poppins", sans-serif;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  color: #fff;
  width: 94%;
  background-color: rgb(30 39 76 / 65%);
  padding: 10px 0;
  font-size: 18px;
  margin: 0;
}
.modal_list_alarm_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: -5px;
}
.modal_list_alarm_row > div {
  width: 50%;
  padding: 5px;
}
.modal_list_alarm_row > div > p {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 600;
  word-wrap: break-word;
}
.side_view_block {
  padding: 20px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1310 !important;
}
.signuo_privacy_notice_block {
  padding: 10px 0 15px 0;
}
.signuo_privacy_notice_block > div {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  margin-left: 3px;
}
.signuo_privacy_notice_block > div input {
  margin-right: 8px;
}
.signuo_privacy_notice_block p,
.signuo_privacy_notice_block p a {
  color: #304863;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.4s ease;
  margin-left: 3px;
}
.signuo_privacy_notice_block p a:hover {
  text-decoration: underline;
  color: #ee7c45;
}
.submit-logout-button-block span {
  padding: 0 10px;
  width: unset !important;
}
.head_container_records {
  margin-top: -15px;
}
.farm-svg-css.pond_record_icon_set > div:nth-child(2) {
  background: rgb(76 176 81 / 10%);
}
.farm-svg-css.pond_record_icon_set > div:nth-child(2) svg path {
  fill: #4cb051;
}
.increment_decrement_row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 18%;
}
.increment_decrement_row > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.expire_date_block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 10px 0;
  margin-right: 160px;
}
.expire_date_block > p {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}
.increment_decrement_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: #ee7c45;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  width: 34px;
  height: 32px;
  margin: 0px;
  cursor: pointer;
}
.increment_decrement_main {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.increment_decrement_main .MuiInputBase-root.MuiInput-root.MuiInput-underline {
  margin: 0 auto;
  border: 1px solid #ddd;
  z-index: 99;
  background-color: #fff;
}
.increment_decrement_main
  .MuiInputBase-root.MuiInput-root.MuiInput-underline
  > input {
  text-align: center;
}
.increment_decrement_main .MuiFormControl-root.MuiTextField-root {
  width: 90px;
  margin: 0;
}
.recordLink_row {
  padding: 10px 20px;
  display: flex;
}
.recordLink_row > p:first-child {
  white-space: nowrap;
}
.recordLink_row a {
  font-size: 16px;
  margin-left: 4px;
}
.link_loafer_block {
  position: fixed;
  left: 55%;
  top: 55%;
  transform: translate(-50%, -50%);
}
.add-Pond-location_popup .MuiButtonBase-root.MuiButton-root > span {
  margin-left: 0;
  margin-right: 5px;
}
.overflow_block {
  position: relative;
}
.overflow_block::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 9999;
}
.link_loafer_block {
  z-index: 9999;
}
.loading_button button {
  width: 100%;
}
.loading_button button > div {
  left: 39% !important;
}
.modal_image_row_cm > div > img {
  width: 100%;
  height: 430px;
}
.pond_recode_map_auto {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 750px;
}
.view_more_box {
  padding: 14px 0;
}
.view_more_box .css-hover-block {
  overflow: hidden;
}
.view_more_box .css-hover-block::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  transition: all 0.3s ease-in-out 0s;
  background-color: #ee7c45;
}
.view_more_box .css-hover-block:hover::after {
  height: 100%;
}
.view_more_box .css-hover-block span img {
  z-index: 111;
  position: relative;
}
.view_more_box .css-hover-block:hover span img {
  filter: brightness(0) invert(1);
}
.view_more_box .css-hover-block h5 {
  z-index: 111;
  position: relative;
}
.view_more_box .css-hover-block:hover h5 {
  color: #fff;
}
.view_more_box .css-hover-block .view_more_btn {
  z-index: 111;
  position: relative;
}
.view_more_chart {
  margin-top: 28px;
  justify-content: center;
}
.view_more_chart .line_chart_main {
  border: 1px solid #e3e3e3;
  padding: 10px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
  height: 400px;
}
.Settings_margin_cm_main .cards_main_block {
  margin: 0;
}
.Settings_margin_cm_main .setting_padding_css.head_container {
  margin-bottom: 0 !important;
}
.form-general-css-block.Settings_input_box form .two.fields .field select {
  box-shadow: 0 1px 5px #c1becd !important;
}
.delete_bg_color .MuiLoadingButton-loadingIndicator {
  left: 35px;
}
/*========================== Sidebar Css End ==========================*/

/*========================== Report One Css Start ==========================*/
.report_pond_wrap .text_sam_block + .text_sam_block {
  margin-top: 20px;
}
.text_sam_block h6 {
  font-size: 17px;
  font-weight: 400;
  color: #000;
  padding-bottom: 4px;
  /* border-bottom: 2px solid rgb(124 165 190); */
}
.text_sam_block .description_sam_text {
  color: #000;
  font-size: 14px;
  font-weight: 300;
}
.description_content {
  margin-top: 10px;
}
.description_content ul {
  padding: 0;
  margin: 0;
}
.measurement_reports_detail .description_content ul li {
  width: 50%;
}
.description_content ul li {
  list-style: none;
  display: flex;
  align-items: flex-end;
}
.description_content ul li + li {
  margin-top: 5px;
}
.description_content ul li p {
  margin: 0;
  width: 12%;
}
.text_sam_block .description_content ul li span {
  font-weight: 400;
}
.summary_row_block {
  display: flex;
  width: 100%;
}
.summary_row_block > ul {
  width: 50%;
}
.measurement_reports_detail .summary_row_block > ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.description_content .summary_row_block ul li p {
  width: 31%;
}
.counting_report_main .description_content .summary_row_block > ul li p ,
.counting_report_main .description_content > ul li > p{
  width: 15%;
  font-weight: 500;
}
.counting_report_main .description_content .summary_row_block > ul li span,
.counting_report_main .description_content > ul li > span{
  font-weight: 300;
}
.chart_block.counting_report_chart{
  /* box-shadow: 0 1px 5px rgb(0 0 0 / 35%) !important; */
  border: 1px solid #ddd;
}
.counting_report_growth_row.growth_chart{
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
  gap: 30px;
  /* margin: -15px; */
}
.counting_report_growth_row.growth_chart > div.chart_col{
  width: 49%;
}
.counting_report_growth_row.growth_chart > div > .chart_block.counting_report_chart{
  /* width: 100%; */
}
.growth_chart {
  padding-top: 20px;
}
.growth_chart .chart_block {
  /* border: 1px solid #7d7d7d; */
  /* box-shadow: 0 1px 5px rgb(0 0 0 / 25%); */
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  /* width: 50%; */
  /* margin-top: 10px; */
  overflow: auto;
}
.growth_bar_col .chart_block {
  border: 1px solid #7d7d7d;
  /* box-shadow: 0 1px 5px rgb(0 0 0 / 25%); */
  padding: 10px;
  background: #fff;
  border-radius: 10px;
}
.growth_average_row {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
.measurement_reports_detail .growth_average_row {
  justify-content: center;
  padding-top: 30px;
}
.growth_average_row > div {
  width: 33.33%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.report_main_wrapper_page {
  margin-top: -10px;
}
.growth_average_row .growth_bar_col p {
  font-size: 15px;
  text-align: center;
  margin-bottom: 6px;
}
.growth_chart p {
  margin-bottom: 6px;
}
.report-button-css-block.button_two button {
  margin-top: 15px;
}
.reports_download {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.reports_main_wrapper {
  margin: 15px 0;
  /* border: 2px solid #0000001f; */
  box-shadow: 0 1px 5px rgb(0 0 0 / 35%) !important;
  padding: 20px;
  background-color: #fff;
}
.main_box_block_css .MuiToolbar-root.MuiToolbar-gutters {
  pointer-events: none;
}
.reports_filter_tab {
  width: 100%;
  margin-bottom: 35px;
}
.reports_filter_tab ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.reports_filter_tab ul li {
  list-style: none;
  width: 50%;
}
.reports_filter_tab ul li a {
  padding: 10px 45px;
  color: #1e274c !important;
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: #919faf;
  border-bottom: 2px solid #e4e8ef;
  text-align: center;
}
.reports_filter_tab ul li.active a {
  border-bottom: 2px solid #1e274c;
  color: #1e274c;
  font-weight: 600;
}
.multi_select_block
  .multiselect-container.multiSelectContainer
  .search-wrapper.searchWrapper {
  width: 100%;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
  margin: 0;
  min-width: 220px;
  padding: 10px 13px;
}
.multi_select_block
  .multiselect-container.multiSelectContainer
  .search-wrapper.searchWrapper
  input {
  font-size: 16px;
}
.multi_select_block
  .multiselect-container.multiSelectContainer
  .search-wrapper.searchWrapper
  input::placeholder {
  color: #000;
}
.multi_select_block
  .multiselect-container.multiSelectContainer
  .optionListContainer {
  margin-top: 1px;
}
.multi_select_block
  .multiselect-container.multiSelectContainer
  .optionListContainer
  li.highlightOption {
  background: #ee7c45 !important;
  color: #fff !important;
}
.multi_select_block
  .multiselect-container.multiSelectContainer
  .optionListContainer
  li:hover {
  background: rgb(238 124 69 / 52%);
  color: #000;
}
.multi_select_block .multiselect-container.multiSelectContainer .chip {
  background: #ee7c45;
}
.all_ponds_list {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.all_ponds_list ul {
  padding: 0;
  margin: 0;
}
.all_ponds_list ul li {
  list-style: none;
}
.all_ponds_list ul li a {
  text-decoration: none;
  padding: 8px 15px;
  display: block;
  font-size: 16px;
  color: #919faf;
  border-left: 2px solid #e4e8ef;
}
.all_ponds_list ul li.active a {
  border-left: 2px solid #1e274c;
  color: #1e274c;
  font-weight: 600;
}
.export_all_button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.export_all_button > button {
  margin-top: -8px;
  margin-bottom: 20px;
}
.search_autocomplete_block .sc-eDDNvR > .wrapper:hover {
  box-shadow: none;
}
.search_autocomplete_block .sc-eDDNvR > .wrapper {
  border-radius: 0;
  position: relative;
  background-color: #fff;
  border: 0;
}
.search_autocomplete_block .sc-eDDNvR > .wrapper .sc-hLseeU {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  margin: 0 15px;
  width: 95%;
  min-height: 35px;
  align-items: flex-end;
  padding-bottom: 5px;
}
.wrapper {
  box-shadow: none !important;
}
.search_autocomplete_block .sc-hLseeU .wrapper:focus-within .sc-hLseeU {
  border-bottom-color: #ee7c45;
}
.search_autocomplete_block:focus-within label {
  top: -10px;
  transition: all 0.4s ease;
}
.search_autocomplete_block {
  position: relative;
}
.search_autocomplete_block .sc-eDDNvR {
  height: 32px;
}
.search_autocomplete_block .sc-gueYoa {
  margin: 0 15px;
}
/* .search_autocomplete_block .sc-gueYoa div{
  border-top: 1px solid #ee7c45;
} */
.search_autocomplete_block label {
  position: absolute;
  left: 14px;
  bottom: 0;
  color: #ee7c45;
  z-index: 111;
  font-size: 13.5px;
  pointer-events: none;
  transition: all 0.4s ease;
}
.search_autocomplete_block .sc-eDDNvR > .wrapper .sc-hLseeU svg {
  display: none;
}
.search_autocomplete_block .feOXTU {
  height: 33px;
}
.search_autocomplete_block {
  margin-top: 16px;
}
.search_autocomplete_block .sc-eDDNvR > .wrapper .sc-hLseeU input {
  padding: 0;
  font-size: 14px;
  line-height: 1;
}
.search_autocomplete_block .sc-eDDNvR > .wrapper .sc-hLseeU input::placeholder {
  color: #ee7c45;
}
/* .select_farm_main{
  margin-top: 250px;
} */
.dashboard_items_row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: -10px;
}
.dashboard_items {
  padding: 10px;
  width: 14%;
  position: relative;
}
.dashboard_card {
  align-items: center;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 15px 0;
  position: relative;
}
.dashboard_items_row .dashboard_items .dashboard_card {
  border: 2px solid #1e274c;
}
.dashboard_card h4 {
  color: #2d557d;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin: 20px 0;
}
.select_farm_main {
  margin-top: -30px;
  height: 100%;
}
.select_farm_main > h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}
.dashboard_card .farmer_img {
  background-color: #51b0f657;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard_card .farmer_img img {
  width: 40px;
  margin-bottom: 5px;
}
.User_Select_farms .input-block {
  width: 100%;
  margin: 13px 0;
}
.User_Select_farms .multi_select_block > p {
  font-weight: 700;
  font-size: 15px;
}
.User_Select_farms
  .multi_select_block
  .multiselect-container.multiSelectContainer
  .search-wrapper.searchWrapper {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0;
  box-shadow: unset;
  padding: 0;
  display: flex;
  align-items: center;
  margin-top: 3px;
  flex-wrap: wrap;
}
.User_Select_farms
  .multi_select_block
  .multiselect-container.multiSelectContainer
  .chip {
  margin-bottom: 0;
  margin-left: 4px;
  font-size: 12px;
  margin-top: 5px;
}
.User_Select_farms
  .multi_select_block
  .multiselect-container.multiSelectContainer
  .search-wrapper.searchWrapper
  input {
  max-height: 38px;
  width: auto;
  border: 0;
  font-size: 14px;
}
.User_Select_farms .multiSelectContainer li {
  padding: 5px 10px;
  font-size: 13.5px;
}
.User_Select_farms
  .multi_select_block
  .multiselect-container.multiSelectContainer
  .optionListContainer {
  margin-top: 1px;
  overflow: auto;
  max-height: 125px;
  z-index: 5;
}
.charts-mian-block {
  width: 100%;
  justify-content: flex-end;
}
.charts-row {
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
}
.charts-row > div {
  padding: 12px;
  width: 50%;
}
.charts-row .view_more_chart {
  margin: 0;
}
.charts-row .line_chart_main_block {
  padding: 0;
  width: 100%;
  max-width: 100%;
}
.charts-cm + div {
  overflow: unset !important;
}
.charts-mian-block .head_one button {
  padding: 0px;
}
.charts-mian-block .head_one button svg {
  width: 31px;
  height: 31px;
}
.logout-css-modal-block .input_row_12 {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}
.logout-css-modal-block .input_row_12 > div {
  width: 50%;
  padding: 0 10px;
  margin: 0;
}
.td-row-arrow {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.td-row-arrow span svg {
  font-size: 17px;
}
.active-bg-red {
  background-color: rgba(239, 83, 80, 0.06) !important;
}
.cm_td.dev_pool_cm .look_icon_css.red_icon_eye {
  background-color: rgba(239, 83, 80, 0.1);
}
.cm_td.dev_pool_cm .look_icon_css.red_icon_eye svg path {
  fill: unset;
}
@media (max-width: 1320px) {
  .charts-row > div {
    width: 100%;
  }
}
/*========================== Report One Css End ==========================*/

@media (max-width: 1800px) {
  .growth_average_row .growth_bar_col p {
    font-size: 13px;
    margin-bottom: 7px;
  }
  .growth_chart {
    padding-top: 15px;
  }
}
@media (max-width: 1600px) {
  /* .growth_chart .chart_block{
    width: 100%;
  } */
  .description_content .summary_row_block ul li p {
    width: 45%;
  }
  .description_content ul li p {
    width: 17%;
  }
}
@media (max-width: 1400px) {
  .description_content .summary_row_block ul li p {
    width: 54%;
  }
  .description_content ul li p {
    width: 20%;
  }
}
@media (max-width: 1290px) {
  .growth_average_row > div {
    width: 50%;
  }
}
@media (max-width: 1200px) {
  .growth_chart .chart_block {
    width: 100%;
  }
  .summary_row_block {
    flex-wrap: wrap;
  }
  .summary_row_block > ul {
    width: 100%;
  }
  .description_content ul li p {
    width: 30%;
  }
}
@media (max-width: 900px) {
  .description_content ul li p {
    width: 36%;
  }
  .reports_main_wrapper {
    padding: 10px;
  }
  .growth_chart .chart_block {
    padding: 0;
  }
  .report_main_wrapper_page {
    margin-top: -25px;
  }
  .description_content ul li + li {
    margin-top: 4px;
  }
  .text_sam_block h6 {
    font-size: 15px;
    padding-bottom: 2px;
  }
  .description_content {
    margin-top: 5px;
  }
  .growth_chart {
    padding-top: 15px;
  }
  .growth_chart > p {
    margin-bottom: 6px;
  }
}

.four_zero_four_image_container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.delete_icon {
  width: 30px;
  height: 30px;
  background: rgb(249 113 110);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
  cursor: pointer;
}

.license_tab_block .tabs {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 60%;
  border-radius: 40px;
  overflow: hidden;
  margin: 10px auto;
}

/* Styling for each tab item */
.license_tab_block .tab-item {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 16px;
}

/* Styling for the active tab */
.license_tab_block .tab-item.active {
  background-color: #1e274c;
  color: #fff;
  font-weight: 500;
}

/* Styling for the tab content */
.license_tab_block .tab-content {
  display: none;
  padding: 20px;
  border: 1px solid #ccc;
}

/* Styling for the active tab content */
.license_tab_block .tab-content.active {
  display: block;
}
.radio_group_block {
  padding: 0 15px;
  padding-top: 12px;
}
.radio_group_block h5 {
  font-size: 17px;
  margin: 0;
}
.radio_group_block .MuiFormGroup-root {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.radio_group_padding .MuiFormControlLabel-root {
  padding: 5px 0;
}
.radio_group_padding
  .MuiFormControlLabel-root
  .MuiButtonBase-root.MuiRadio-root {
  padding: 0px 10px 0 9px;
}

.MuiButtonBase-root.Mui-disabled.MuiButton-root.license_generate_btn {
  padding-left: 38px;
}
.common_title_css {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.common_title_text {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}
.common_title_description {
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
}
.autocomplete_country .MuiAutocomplete-root .MuiInputBase-root fieldset {
  top: 0;
}
.autocomplete_country
  .MuiAutocomplete-root
  .MuiInputBase-root.Mui-focused
  fieldset,
.autocomplete_country .MuiAutocomplete-root .MuiInputBase-root:hover fieldset {
  border-color: rgba(34, 36, 38, 0.15) !important;
  border-width: 1px;
}
.autocomplete_country .MuiAutocomplete-root .MuiInputBase-root fieldset legend {
  display: none;
}
.autocomplete_country .MuiAutocomplete-root .MuiInputBase-root {
  padding: 0;
}
.signUp_form_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.signUp_form_row > div {
  width: 50%;
  margin: 0 !important;
  padding: 10px;
}

.timezone-field .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
  padding: 0;
}

.timezone-field
  .MuiAutocomplete-root
  .MuiFormControl-root
  .MuiInputBase-root
  fieldset {
  border-color: transparent;
}
.MuiAutocomplete-endAdornment button:first-child {
  margin-right: 3px;
}

.expanded_rows tbody {
  border: 1px solid rgba(34, 36, 38, 0.1);
}
table.table.expanded_rows {
  border-radius: 0 !important;
}

table.table.expanded_rows thead tr th {
  background-color: transparent !important;
  color: #1e274c !important;
  border: 0 !important;
  border-bottom: 1px solid #1e274c !important;
}
table.table.expanded_rows tbody,
table.table.expanded_rows tbody tr td {
  border-left: 0 !important;
  border-right: 0 !important;
}

.user-manage-tab tbody tr td.arrow_set div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button-row-block{
  display: flex;
  flex-direction: row;
  gap: 13px;
}
.full_screen {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}
.full_screen img{
  width: 25px;
  height: 25px;
  cursor: pointer;
}