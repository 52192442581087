.phone_msg {
    font-size: 15px;
    font-weight: 500;
    color: #304863;
    margin-top: 5px;
    /* margin-bottom: 8px; */
}

.move_login_container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.move_login_text {
    color: #304863;
    font-weight: 500;
    font-size: 15px;
}

.move_login {
    text-decoration: underline;
}

