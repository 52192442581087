.head_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}
.head_devices.head_container {
  margin-top: 0;
}

.head_one {
  display: flex;
  flex-direction: row;
}

.base_container {
  margin-left: 5px;
}

.head_two_list {
  width: auto;
}

@media screen and (max-width: 1100px) {
  .head_container {
    flex-direction: column !important;
  }
  .base_container {
    /* margin-top: 10px; */
    margin-left: 0px;
  }
  .head_two {
    margin-top: 20px;
    margin-left: 12px;
  }
}
/* @media screen and (max-width: 1100px) {
    .head_one {
        flex-direction: column !important;
    }
    .base_container {
        margin-top: 10px;
        margin-left: 0px;
    }
    .head_two {
        margin-top: 10px;
    }
} */
