/* .gm-ui-hover-effect img {
    width: 40px !important;
    height: 40px !important;
    } */

    .gm-ui-hover-effect {
        /* top: 0.05px !important; */
    /* right: 2px !important; */
    width: 40px !important;
    height: 40px !important;
    }

    button.gm-ui-hover-effect {
        visibility: hidden;
    }