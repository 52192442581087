.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #304863; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:horizontal {
  height: 0px;
} */
