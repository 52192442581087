.move_register_container {
    display: flex;
    justify-content: center;
}

.move_register_text {
    color: #304863;
    font-weight: 500;
    font-size: 15px;
}

.move_register {
    text-decoration: underline;
    /* text-decoration-color: black; */
    /* text-decoration-line: underline; */
}


